<template>
    <div class="w-full h-full flex flex-col items-center md:justify-center bg-beige overflow-auto">
        <div 
            v-if="confirmInProgress"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <h2>
                    Bekreft e-post
                </h2>
                <p>
                    For å bekrefte at du er eieren av denne e-postadressen, har vi sendt deg en e-post med en bekreftelseskode.
                </p>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="resetPasswordSubmit()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="E-post"
                        name="email"
                        placeholder="john@example.com"
                        class="w-full"
                        type="email"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="confirmCode"
                        label="Bekreftelseskode"
                        name="confirmCode"
                        placeholder="verificationCode"
                        class="w-full -mt-4"
                        :required="true"
                    />
                    
                    <InputSingleline 
                        v-model="password"
                        label="Nytt passord"
                        name="newPassword"
                        class="w-full "
                        type="password"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit my-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Angi nytt passord
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        Gå tilbake til pålogging
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="!confirmInProgress"
            class="flex flex-col md:flex-row m-8 md:rounded-lg md:overflow-hidden">
            
            <div class="w-full md:w-64 bg-gray-darker text-white p-8 pl-12 flex flex-col rounded-t-lg md:rounded-none">
                <h2>
                    Tilbakestill passord
                </h2>
                <p>
                    Vi vil sende deg en e-post med en engangskode som gjør at du kan sette nytt passord.
                </p>
            </div>
            <div class="w-full md:w-auto flex-grow bg-white p-8 md:px-12 h-full flex flex-col rounded-b-lg md:rounded-none">
                <form @submit.prevent="resetPasswordEmail()">
                    <div v-if="error" class="bg-red-600 text-white p-4 mb-12 rounded text-center">
                        <!-- <h2 class="text-white">Error</h2> -->
                        {{error}}
                    </div>
                    
                    <InputSingleline 
                        v-model="email"
                        label="E-post"
                        name="email"
                        placeholder="john@example.com"
                        class="w-full -mt-4"
                        type="email"
                        :required="true"
                    />
                    
                    <button 
                        type="submit" 
                        class="button submit my-4 w-full" 
                        :class="{'loading' : loading}"
                        :disabled="loading">
                        
                        Fortsett
                    </button>
                </form>
                
                <div class="text-center">
                    <router-link :to="{ name: 'signin'}">
                        Gå tilbake til pålogging
                    </router-link>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
    import AuthMixins from '@/mixins/AuthMixins';
    
    export default {
        mixins: [
            AuthMixins,
        ],
        
        data() {
            return {
                email: null,
                error: null,
                confirmInProgress: null,
                confirmCode: null,
                password: null,
                loading: false,
            }
        },
    }
</script>
